<template>
  <div>
    <div>
      <h4 class="text-center" style="font-size: 24px; font-weight: 900">
        Answers for : <span class="text-info">{{ exam.name }}</span>
      </h4>

      <div class="finishExam text-left">
        <vue-countdown v-if="examStart" :time="remainingTimeInSeconds * 1000" v-slot="{ hours, minutes, seconds }"
          @end="examTimeEnded">
          <h5>
            <b :class="{ 'time-running-low': hours == 0 && minutes < 5 }">
              <span class="text-info"><i class="fas fa-hourglass-start"></i></span>
              {{ hours }} hr {{ minutes }} min, {{ seconds }} sec
            </b>
          </h5>
        </vue-countdown>
      </div>
      <br />
      <div class="answersToQuestions">
        <div v-for="(question, questionIndex) in examQuestions" :key="questionIndex" class="answers">
          <b-overlay v-if="question.question" :show="loadData" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
            <b-card class="mt-1" header-tag="header" footer-tag="footer">
              <template #header>
                <div class="question d-flex justify-content-between align-items-baseline">
                  <p class="text-dark">
                    <span v-if="exam.shuffle_questions">{{
                      questionIndex + 1
                    }}</span>
                    <span v-else>
                      {{
                        question.question_no != ""
                        ? question.question_no + ": "
                        : ""
                      }}
                    </span>
                    <span v-for="(qData, qdIndex) in question.question" :key="qdIndex" class="mr-1">
                      <span class="big-font" v-if="qData.type == 'text'" v-html="latexCodeToMathLive(qData.value)"></span>
                      <img class="questionPaperPreviewImg" v-if="qData.type == 'image'" style="" height="100px"
                        width="150px" :src="cloudStorageRoot +
                          questionAttachmentDetails(qData.value, question)
                            .file_path +
                          questionAttachmentDetails(qData.value, question)
                            .file_name
                          " />

                    </span>
                    <span>
                      (score for right answer:{{ question.maximum_mark }} ,
                      score for wrong answer:{{
                        question.wrong_answer_mark
                      }})</span>
                  </p>
                </div>
                <div class="choices" v-for="(choice, cIndex) in question.choices" :key="cIndex">
                  <b-form-radio v-model="examQuestionsAnswer[question.id].choice_id" :value="choice.id">
                    {{ choice.name }}
                  </b-form-radio>
                </div>
              </template>
            </b-card>
          </b-overlay>
        </div>
      </div>
      <br />
      <div class="finishExam text-center">
        <b-button variant="danger" size="md" class="ml-3" @click="closeExam()">Close</b-button>
        <b-button variant="success" size="md" class="ml-3" @click="$bvModal.show('finishExam')">Finish Exam</b-button>
      </div>
      <b-modal id="finishExam" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-header-close hide-header
        hide-footer centered size="sm">
        <p class="text-danger" style="font-size: 18px">
          <span>Are you sure you want to submit the exam ?</span>
        </p>
        <div class="acceptOrNot">
          <b-button variant="primary" size="md" @click="finishExam" pill>Yes</b-button>
          <b-button variant="danger" class="ml-3" size="md" @click="$bvModal.hide('finishExam')" pill>No</b-button>
        </div>
      </b-modal>
    </div>
    <b-modal id="ScoreModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-header-close hide-header
      hide-footer centered size="sm">
      <p class="text-success" style="font-size: 18px">
        <span>Score obtained : {{ totalScore }}/{{ maxScore }}</span>
      </p>
      <div class="acceptOrNot">
        <b-button variant="primary" class="ml-3" size="md" @click="$router.push('/publicexam/dashboard')"
          pill>Ok</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import * as MathLive from "mathlive";
Vue.use(MathLive);
import VueCountdown from "@chenfengyuan/vue-countdown";
import moment from "moment";

export default {
  async created() {
    await this.startExam();
    await this.prepareQuestionAnswerForm();
    await this.setExamTime();
    this.cloudStorageRoot = this.$store.getters.getAPIKey.userAttachments;
  },
  components: {
    VueCountdown,
  },
  data() {
    return {
      questionAttachments: {},//id:{id, url, name}
      loadData: false,
      cloudStorageRoot: "",
      exam: {}, //
      answerArray: [],
      examQuestions: [], //
      examAnswers: [], //
      totalScore: 0,
      maxScore: 0,
      examQuestionsAnswer: {}, //{question_id:answerobject, ...}
      examStart: null,
      remainingTimeInSeconds: "",
    };
  },
  methods: {
    latexCodeToMathLive(codeText) {
      if (codeText == null || codeText == "") return "";
      return codeText.replace(
        /\$\$(.*?)\$\$/g,
        '<math-field class="p-0" style="border: none;" readonly>$1</math-field>'
      );
    },
    setExamTime() {
      var endingTime = moment(
        moment.utc(this.exam.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
      );
      if (!endingTime.isValid()) return;
      var startTime = moment();
      if (startTime >= endingTime) {
        // exam end time passed ( less than current time)
        this.$toast.error("Exam time has ended!", {
          position: "top",
          duration: 3000,
        });
        this.examStart = false;
        this.examTimeEnded();
        return;
      }
      let remainingTimeSesconds = endingTime.diff(startTime, "seconds"); // end time - current time to get remaining seconds
      if (this.exam.limitted_time_seconds) {
        // time is limitted
        var limitted_time_seconds = this.exam.limitted_time_seconds;
        var userStart = null;
        if (this.participant.starts_at) {
          // user already started so limitted time should reduce
          userStart = moment(
            moment
              .utc(this.participant.starts_at, "YYYY-MM-DD HH:mm:ss")
              .toDate()
          );
          var timePassed = moment().diff(userStart, "seconds");
          if (timePassed > limitted_time_seconds) {
            this.$toast.error("Exam time has already ended!", {
              position: "top",
              duration: 3000,
            });
            this.examStart = false;
            this.examTimeEnded();
            return;
          }
          limitted_time_seconds = limitted_time_seconds - timePassed; // reduce available time by already used time
        }
        remainingTimeSesconds =
          remainingTimeSesconds < limitted_time_seconds
            ? remainingTimeSesconds
            : limitted_time_seconds;
      }
      this.remainingTimeInSeconds = remainingTimeSesconds;
      this.examStart = true;
    },
    examTimeEnded() {
      // this.examStart = 0;
      // this.$bvModal.show("finishExam");
      this.finishExam();
    },
    closeExam() {
      if (this.$route.params.type == 0) {
        this.$router.push("/publicexam/dashboard");
      } else {
        this.closeCurrentTab();
      }
    },
    async finishExam() {
      let finish = this.$route.params.type == 1 ? "" : "/publicexampublicuser";
      this.examQuestions.forEach((qn) => {
        this.answerArray.push({
          examquestion_id: qn.id,
          choice_id: this.examQuestionsAnswer[qn.id].choice_id,
        });
      });
      let url =
        this.$store.getters.getAPIKey.mainAPI + `${finish}/publicexamanswers`;
      await axios
        .post(url, {
          answers: this.answerArray,
          public_exam_id: this.$route.params.exam_id,
        })
        .then((response) => {
          this.$toast.success("Exam Finished successfully", {
            position: "top",
            duration: 3000,
          });
          response;
          if (this.$route.params.type == 0) {
            this.$bvModal.hide("finishExam");
            setTimeout(() => {
              this.$router.push("/publicexam/dashboard");
            }, 3000);
          } else {
            this.$bvModal.hide("finishExam");
            setTimeout(() => {
              this.closeCurrentTab();
            }, 3000);
          }
        })
        .catch((error) => {
          // this.validationErrors[examQuestionId] = true;
          this.$forceUpdate();
          error;
        });
    },
    async saveQuestionsAnswers() {
      let answerResponses = {};
      this.maxScore = [];
      for (let index = 0; index < this.examQuestions.length; index++) {
        answerResponses[this.examQuestions[index].id] =
          await this.putQuestionAnswer(this.examQuestions[index].id);
        this.maxScore =
          Number(this.maxScore) +
          Number(this.examQuestions[index].maximum_mark);
      }
      this.$toast.success("Exam Finished successfully", {
        position: "top",
        duration: 3000,
      });
      this.$bvModal.hide("finishExam");
    },
    async prepareQuestionAnswerForm() {
      await this.examQuestions.forEach((question) => {
        this.$set(this.examQuestionsAnswer, question.id, {
          id: null,
          text: "",
          files: [],
          choice_id: null,
        });
      });
      await this.getAnswers();
    },
    async getAnswers() {
      let getAnswer =
        this.$route.params.type == 1 ? "" : "/publicexampublicuser";
      let answers = null;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `${getAnswer}/publicexamanswers?public_exam_id=${this.exam.id}`;
      await axios
        .get(url)
        .then((response) => {
          answers = response.data;
          return (this.examAnswers = answers);
        })
        .catch(() => { });
      await answers.forEach((answer) => {
        // this.uploadPercentage[answer.id] = 0;
        this.examQuestionsAnswer[answer.examquestion_id] = {
          id: answer.id,
          text: answer.answer == null ? "" : answer.answer,
          files: answer.files,
          choice_id: answer.choice_id == null ? null : answer.choice_id,
          score: answer.score,
        };
      });
      this.$forceUpdate();
      return answers;
    },
    questionAttachmentDetails(attachmentId, question) {
      return question.files.find((item) => item.id == attachmentId);
    },
    async startExam() {
      let publicexam =
        this.$route.params.type == 1 ? "/publicexams" : "/publicexampublicuser";
      const examId = this.$route.params.exam_id;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `${publicexam}/start/${examId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.participant = response.data.examParticipant;
          this.exam = response.data.exam;
          this.examQuestions = response.data.questionsChoices;




                    
        })
        .catch(() => {
          this.$toast.error("Exam time has ended!", {
            position: "top",
            duration: 3000,
          });
          this.examStart = false;
          this.examTimeEnded();
          return;
        });
    },
  },
};
</script>

<style>
.time-running-low {
  font-size: 20px !important;
  color: red;
}
</style>
